import React from "react";
import { ButtonNext } from "./../common/ButtonNext";
import { useDispatch } from "react-redux";
import { next } from "../../features/activeStepSlice";

const StepContent2 = () => {
  const dispatch = useDispatch();

  const handleNext = () => {
    dispatch(next());
  };

  return <ButtonNext text="Continue" onClick={handleNext} />;
};

export default StepContent2;
