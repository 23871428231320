import React from "react";
import { ButtonNext } from "./../common/ButtonNext";
import { useDispatch } from "react-redux";
import { next } from "../../features/activeStepSlice";
import { addField } from "../../features/userDataSlice";

const StepContent8 = () => {
  const dispatch = useDispatch();

  const handleNext = () => {
    dispatch(next());
    dispatch(addField({ gender: "Male" }));
  };

  return <ButtonNext text="Continue" onClick={handleNext} />;
};

export default StepContent8;
