import React, { useEffect, useState } from "react";
import { Box, IconButton, useTheme, styled, Typography } from "@mui/material";
import { ButtonNext } from "../common/ButtonNext";
import { ErrorMessage } from "../common/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../features/userDataSlice";
import { addErrorMessage } from "../../features/errorMessageSlice";
import { next } from "../../features/activeStepSlice";
import { useDebounce } from "../../hooks/useDebounce";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Translate } from "react-translated";
import { InputComp } from "../common/InputComp";

const PasswordCompBox = styled(Box)(({ theme }) => ({
  margin: "16px auto 0",
  width: "343px",
  maxWidth: "100%",

  [theme.breakpoints.up("sm")]: {
    width: "466px",
    margin: "24px auto 0",
  },
}));

const StepContent13 = () => {
  const userData = useSelector((state) => state.userData?.value);
  const [password, setPassword] = useState(userData.password || "");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const regex = /^(?=.*\d).{6,20}$/;
  const message = "passwordErrMessFE";
  const useDebounceValue = useDebounce(password, 1000, regex, message);
  const theme = useTheme();

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length < 21) {
      setPassword(value);
      dispatch(addErrorMessage(""));
    }
  };

  useEffect(() => {
    if (useDebounceValue || !errorMessage) {
      dispatch(addField({ password: useDebounceValue }));
    } else {
      dispatch(addField({ password: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDebounceValue]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleNext = () => {
    if (useDebounceValue && password) {
      try {
        dispatch(next());
        dispatch(addErrorMessage(""));
      } catch (error) {
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
      }
    }
  };

  return (
    <PasswordCompBox className="userBox">
      {!errorMessage ? (
        <Typography
          variant="Body4"
          sx={{
            color: theme.palette.grey,
            marginBottom: "16px",
            fontSize: { xs: "12px", sm: "14px" },
            lineHeight: { xs: "18px", sm: "24px" },
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
          component="p"
        >
          <Translate text="passwordText" />
        </Typography>
      ) : (
        <ErrorMessage errorMessage={errorMessage} />
      )}

      <InputComp
        name="passwordMain"
        value={password}
        handleChange={handleChange}
        placeholder="passwordPlaceholder"
        typeInput={showPassword ? "text" : "password"}
        isValid={!useDebounceValue || !!errorMessage}
        extraComp={
          <IconButton onClick={togglePasswordVisibility}>
            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        }
        autoFocus={true}
      />

      <ButtonNext
        text="Continue"
        onClick={handleNext}
        style={{ cursor: useDebounceValue ? "pointer" : "no-drop" }}
        disabled={!useDebounceValue || !password}
      />
    </PasswordCompBox>
  );
};

export default StepContent13;
