import React, { useState } from "react";
import { ButtonNext } from "./../common/ButtonNext";
import { useDispatch, useSelector } from "react-redux";
import { next } from "../../features/activeStepSlice";
import { MultipleCheckbox } from "../common/MultipleCheckbox";
import { addField } from "../../features/userDataSlice";
import { Box } from "@mui/material";

const StepContent7 = () => {
  const activeStep = useSelector((state) => state.activeStep.value);
  const userData = useSelector((state) => state.userData?.value);
  const step7Data = ["blonde", "brunette", "redhead", "dontKnow"];
  const dispatch = useDispatch();
  const [checkedItems, setCheckedItems] = useState(
    userData.step7 || {
      blond: false,
      brunette: false,
      readhead: false,
      dontKnow: false,
    }
  );
  const oneChecked = Object.values(checkedItems).some((value) => value);

  const handleCheckboxChange = (name) => {
    setCheckedItems((prev) => {
      const updatedState = {
        ...prev,
        [name]: !prev[name],
      };
      const selectedCount = Object.values(updatedState).filter(Boolean).length;

      if (selectedCount > 2 && updatedState[name]) {
        return prev;
      }

      dispatch(addField({ step7: updatedState }));
      return updatedState;
    });
  };

  const handleNext = () => {
    dispatch(next());
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <MultipleCheckbox
        arrayData={step7Data}
        checkedItems={checkedItems}
        handleCheckboxChange={handleCheckboxChange}
        stepNumber={7}
        text={`step${activeStep}_warning_text`}
      />
      <ButtonNext text="Continue" onClick={handleNext} disabled={!oneChecked} />
    </Box>
  );
};

export default StepContent7;
