import React from "react";
import {
  Stack,
  Box,
  styled,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import { progressBarCenter } from "../helpers/progressBarCenter";

const ProgressBarCompWrapper = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  height: "40px",
  minHeight: "42px",
  marginBottom: "32px",
  boxSizing: "border-box",
  position: "sticky",
  top: "0px",
  zIndex: "100",

  [theme.breakpoints.up("md")]: {
    height: "50px",
    minHeight: "52px",
    marginBottom: "40px",
  },

  [theme.breakpoints.up("lg")]: {
    margin: "0 auto 40px",
  },
}));

const ProgressBarCompStack = styled(Stack)(({ theme, transformleft }) => ({
  display: "flex",
  flexDirection: "row",
  transform: `translateX(-${transformleft}px)`,
  transition: "all 2s ease",

  [theme.breakpoints.up("lg")]: {
    transform: "none",
  },
}));

const StepBox = styled(Box)(({ theme }) => ({
  minWidth: "36px",
  height: "36px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  marginRight: "12px",
  boxSizing: "border-box",

  ":last-child": {
    marginRight: 0,
  },

  [theme.breakpoints.up("md")]: {
    minWidth: "50px",
    height: "50px",
    marginRight: "18px",
    userSelect: "none",
  },
}));

export const ProgressBarComp = () => {
  const activeStep = useSelector((state) => state.activeStep.value);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ProgressBarCompWrapper>
      <ProgressBarCompStack transformleft={progressBarCenter(activeStep)}>
        {[...Array(14).keys()].map((item) => (
          <StepBox
            key={item}
            style={{
              background:
                activeStep >= item + 1
                  ? theme.palette.orange
                  : theme.palette.lightGrey,
              border:
                activeStep >= item + 1
                  ? "none"
                  : `1px solid ${theme.palette.grey}`,
            }}
          >
            <Typography
              variant={isSmallScreen ? "Subtitle4" : "Subtitle3"}
              color={
                activeStep >= item + 1
                  ? theme.palette.white
                  : theme.palette.grey
              }
            >
              {item + 1}
            </Typography>
          </StepBox>
        ))}
      </ProgressBarCompStack>
    </ProgressBarCompWrapper>
  );
};
