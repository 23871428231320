import React from "react";
import { Box, styled, keyframes } from "@mui/material";

const ImageOnlineStatus = styled(Box)(() => ({
  position: "relative",
  marginRight: "24px",
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: "62px",
  height: "62px",

  [theme.breakpoints.up("md")]: {
    width: "80px",
    height: "80px",
  },
}));

const OnlineStatusBox = styled(Box)(({ theme }) => ({
  width: "12px",
  height: "12px",
  background: theme.palette.lightGreen,
  borderRadius: "50%",
  position: "absolute",
  boxShadow: "0 0 5px #fff",
  animation: `${pulse} 1s infinite`,
  border: "1px solid #fff",
  right: 0,
  top: "46px",

  [theme.breakpoints.up("md")]: {
    top: "57px",
    width: "14px",
    height: "14px",
  },
}));

const pulse = keyframes`
  0% {transform: scale(1)}
  50% {transform: scale(1.05); opacity: 0.9}
  100% {transform: scale(1)}
`;

export const ImageWithStatus = () => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <ImageOnlineStatus>
      <ImageBox
        component="img"
        src={require(`./../../images/questionImg_${dynamicType}.png`)}
        alt="questionImg"
      />
      <OnlineStatusBox />
    </ImageOnlineStatus>
  );
};
