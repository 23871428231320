import React from "react";
import {
  Box,
  Divider,
  Stack,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ProgressBarComp } from "./ProgressBarComp";
import { TextComp } from "./common/TextComp";
import { TimerComp } from "./TimerComp";
import { QuestionComp } from "./QuestionComp";
import { ButtonBack } from "./common/ButtonBack";
import { useDispatch, useSelector } from "react-redux";
import { back } from "../features/activeStepSlice";
import { addErrorMessage } from "../features/errorMessageSlice";

const MainContentStack = styled(Stack)(({ bgimage }) => ({
  backgroundImage: `url(${bgimage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed",
  width: "100%",
  height: "auto",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "375px",
  minHeight: "100vh",
}));

const FormContentStack = styled(Stack)(({ theme }) => ({
  minWidth: "375px",
  width: "375px", //>0 to <600
  minHeight: "500px",
  background: "rgba(255, 255, 255, 0.93)",
  padding: "16px 16px 24px ",
  boxShadow: "0px 0px 12px 0px #00000014",
  boxSizing: "border-box",
  height: "100vh",
  overflow: "hidden",

  [theme.breakpoints.up("sm")]: {
    borderRadius: "12px",
    margin: "20px auto",
    padding: "32px 24px 48px",
    width: "500px", //>600 to <960
    maxHeight: "94vh",
    height: "auto",
  },

  [theme.breakpoints.up("md")]: {
    width: "800px", //>960 to <1280
  },

  [theme.breakpoints.up("lg")]: {
    minHeight: "600px",
    width: "1000px", //>1280
  },

  [theme.breakpoints.up("xl")]: {
    overflow: "visible",
  },
}));

const MainContentBox = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  flex: "1 1 auto",
  display: "flex",
  flexDirection: "column",

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#cacaca",
    border: "4px solid transparent",
    borderRadius: "8px",
    backgroundClip: "padding-box",
  },

  "&::-webkit-scrollbar": {
    width: "12px",
  },

  [theme.breakpoints.up("xl")]: {
    overflowY: "visible",
  },
}));

export const MainContent = () => {
  const activeStep = useSelector((state) => state.activeStep.value);
  const dispatch = useDispatch();
  const StepContent = require("./stepsComps/StepContent" + activeStep).default;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;
  const isBgStatic = process.env.REACT_APP_BG_STATIC === "YES";
  //need to remove oddOrEven variable, it's just for test, odd: 1,3,5,7,9
  const oddOrEven = activeStep % 2 === 0 ? "2" : "1";
  const bgimage = isBgStatic
    ? require(`./../images/mainBgImg_${dynamicType}.png`)
    : require(`./../images/bgStatic_${oddOrEven}_${dynamicType}.png`);

  const handleBack = () => {
    dispatch(back());
    dispatch(addErrorMessage(""));
  };

  return (
    <MainContentStack bgimage={bgimage}>
      <FormContentStack>
        <ProgressBarComp />
        <MainContentBox>
          <TextComp
            variant={isSmallScreen ? "Subtitle2" : "Subtitle1"}
            text="titleText"
            sx={{ mb: { xs: "12px", sm: 2 } }}
            component="h2"
          />
          <TimerComp />
          <Divider sx={{ my: { xs: "24px", md: "30px", xl: "44px" } }} />
          <QuestionComp />
          <StepContent />
          {activeStep > 1 && <ButtonBack onClick={handleBack} />}
        </MainContentBox>
      </FormContentStack>
    </MainContentStack>
  );
};
