import React from "react";
import { Button, styled, useTheme, useMediaQuery } from "@mui/material";
import { TextComp } from "./TextComp";

const ButtonCompStles = styled(Button)(({ theme }) => ({
  height: "44px",
  width: "343px",
  borderRadius: "16px",
  margin: "28px auto 0",
  textTransform: "none",
  border: `2px solid ${theme.palette.black}`,

  [theme.breakpoints.up("md")]: {
    height: "48px",
    width: "466px",
    margin: "48px auto 0",
  },
}));

export const ButtonComp = ({
  text,
  disabled = false,
  sx = {},
  onClick = null,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ButtonCompStles
      onClick={onClick}
      disabled={disabled}
      sx={sx?.borderStyles}
    >
      <TextComp
        text={text}
        variant={isSmallScreen ? "Body4" : "Body2"}
        sx={{ color: theme.palette.black, ...sx?.textStyles }}
      />
    </ButtonCompStles>
  );
};
