import React from "react";
import { Typography } from "@mui/material";
import { dialogContent } from "../../dataUI/dialogContent";

export const DialogBoldText = ({ text, sx }) => {
  return (
    <Typography variant="Body5" sx={{ ...sx, fontWeight: "600" }}>
      {dialogContent[text]}
    </Typography>
  );
};
