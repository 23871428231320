import { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { Provider } from "react-translated";
import translation from "./translations";
import { MainContent } from "./components/MainContent";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { efTransactionId } from "./helpers/efTransactionId";
import { addField } from "./features/userDataSlice";
import { useDispatch } from "react-redux";

const App = () => {
  const language = process.env.REACT_APP_LANGUAGE;
  const dispatch = useDispatch();
  const helmetContext = {};

  useEffect(() => {
    const EF = window.EF;
    if (typeof window !== "undefined" && EF !== null) {
      EF.click(efTransactionId()).then((tid) => {
        tid && dispatch(addField({ ef_transaction_id: tid }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HelmetProvider context={helmetContext}>
      <Provider language={language} translation={translation}>
        <ThemeProvider theme={theme}>
          <Helmet>
            <title>{process.env.REACT_APP_TITLE || "IntimFlørts"}</title>
            <meta
              name="description"
              content={
                process.env.REACT_APP_DESCRIPTION ||
                "Bli Med og Flørt - Møt Din Match I Dag"
              }
            />
          </Helmet>
          <MainContent />
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
