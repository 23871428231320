import React from "react";
import { TextComp } from "./TextComp";

export const ButtonBack = ({ onClick = null }) => {
  return (
    <TextComp
      text="Back"
      variant="Subtitle4"
      sx={{
        width: "fit-content",
        cursor: "pointer",
        margin: "12px auto 0",
        px: 2,
        userSelect: "none",
        fontSize: { md: "18px" },
      }}
      onClick={onClick}
    />
  );
};
