import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  Link,
  styled,
  useTheme,
} from "@mui/material";
import { ButtonNext } from "../common/ButtonNext";
import { ErrorMessage } from "../common/ErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { addField } from "../../features/userDataSlice";
import { addErrorMessage } from "../../features/errorMessageSlice";
import { setActiveStep } from "../../features/activeStepSlice";
import { Translate } from "react-translated";
import { useDebounce } from "../../hooks/useDebounce";
import axios from "axios";
import { RecaptchaToken } from "./../common/RecaptchaToken";
import { InputComp } from "../common/InputComp";
import { ModalComp } from "../common/ModalComp";

const EmailCompBox = styled(Box)(({ theme }) => ({
  margin: "16px auto 0",
  width: "343px",
  maxWidth: "100%",

  [theme.breakpoints.up("sm")]: {
    width: "466px",
    margin: "24px auto 0",
  },
}));

const StepContent14 = () => {
  const userData = useSelector((state) => state.userData?.value);
  const [email, setEmail] = useState(userData.email || "");
  const [isChecked, setIsChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const apiUrl = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const userId = sessionStorage.getItem("user_id");
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const message = "emailErrMessFE";
  const useDebounceValue = useDebounce(email, 1000, regex, message);
  const theme = useTheme();

  const handleChange = (e) => {
    setEmail(e.target.value);
    dispatch(addErrorMessage(""));
  };

  useEffect(() => {
    if (useDebounceValue || !errorMessage) {
      dispatch(addField({ email: useDebounceValue }));
    } else {
      dispatch(addField({ email: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDebounceValue]);

  const fetchCompleteData = async () => {
    try {
      const response = await apiUrl.post(
        `/registration/${userId}`,
        {
          email: useDebounceValue,
          DOB: `${userData.year}-${userData.month}-${userData.day}`,
          location: userData.location,
          gender: userData.gender,
          password: userData.password,
          looking_for: userData.looking_for,
          recaptcha_token: userData.recaptcha_token,
          ef_transaction_id: userData.ef_transaction_id,
        },
        {
          params: {
            site_key: "no01",
          },
        }
      );

      const getAccessToken = await apiUrl.post(
        `/sessions`,
        {
          password: userData.password,
          email: useDebounceValue,
          recaptcha_token: userData.recaptcha_token,
        },
        {
          params: {
            site_key: "no01",
          },
        }
      );
      localStorage.setItem(
        "access_token",
        getAccessToken?.data?.Data?.access_token
      );
      const accessToken = localStorage.getItem("access_token");

      if (response?.data.Status === "ok" && userId && accessToken) {
        window.location.href = `https://intimflorts.com/discovery/?token=${accessToken}`;
        dispatch(addErrorMessage(""));
      }
    } catch (error) {
      if (error?.response?.data.Status === "fail") {
        errorCodeChangeActiveState(error?.response?.data?.Error?.code);
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
      }
    }
  };

  const errorCodeChangeActiveState = (code) => {
    // age error
    if (code === 103) {
      dispatch(setActiveStep(10));
    }
    // password error
    else if (code === 102 || code === 105 || code === 118) {
      dispatch(setActiveStep(13));
    }
    // email error
    else if (code === 100 || code === 106) {
      dispatch(setActiveStep(14));
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev);
  };

  const handleNext = () => {
    if (useDebounceValue && isChecked) {
      try {
        dispatch(addErrorMessage(""));
        fetchCompleteData();
      } catch (error) {
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
      }
    }
  };

  const handleOpenModal = (type) => {
    setModalOpen(true);
    setModalType(type);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalType("");
  };

  return (
    <EmailCompBox className="userBox">
      {!errorMessage ? (
        <Typography
          variant="Body4"
          sx={{
            color: theme.palette.grey,
            marginBottom: "16px",
            fontSize: { xs: "12px", sm: "14px" },
            lineHeight: { xs: "18px", sm: "24px" },
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
          component="p"
        >
          <Translate text="emailText" />
        </Typography>
      ) : (
        <ErrorMessage errorMessage={errorMessage} />
      )}

      <InputComp
        name="emailMain"
        value={email}
        handleChange={handleChange}
        placeholder="emailPlaceholder"
        typeInput="email"
        isValid={!useDebounceValue || !email || !userData.recaptcha_token}
        autoFocus={true}
      />

      <FormGroup sx={{ marginTop: "16px" }}>
        <FormControlLabel
          sx={{
            margin: "0",
            display: "flex",
            alignItems: "flex-start",
          }}
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              sx={{
                padding: "2px",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&.Mui-checked": {
                  color: theme.palette.lightGreen,
                },
              }}
            />
          }
          label={
            <Typography
              variant="Body3"
              color={theme.palette.black}
              sx={{
                marginLeft: "10px",
                fontSize: { xs: "12px", sm: "14px" },
                lineHeight: { xs: "18px", sm: "24px" },
              }}
            >
              <Translate text="read and accept" />{" "}
              <Link
                sx={{ color: theme.palette.lightGreen, textDecoration: "none" }}
                onClick={() => handleOpenModal("terms")}
              >
                <Translate text="Terms of Service" />
              </Link>{" "}
              <Translate text="and our" />{" "}
              <Link
                sx={{ color: theme.palette.lightGreen, textDecoration: "none" }}
                onClick={() => handleOpenModal("privacy")}
              >
                <Translate text="Privacy Statement" />
              </Link>
            </Typography>
          }
        />
      </FormGroup>

      <ModalComp
        open={modalOpen}
        handleClose={handleCloseModal}
        type={modalType}
      />

      <ButtonNext
        onClick={handleNext}
        text="Continue"
        style={{ cursor: !useDebounceValue ? "pointer" : "no-drop" }}
        disabled={
          !useDebounceValue ||
          !isChecked ||
          !!errorMessage ||
          !email ||
          !userData.recaptcha_token
        }
      />
      <RecaptchaToken />
    </EmailCompBox>
  );
};

export default StepContent14;
