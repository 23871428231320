export const buttons = {
  //Form buttons
"Back_aggressive": {
  en: `Back`,
  no: `Tillbake`,
},
"Back_casual": {
  en: `Back`,
  no: `Tillbake`,
},
"Back_mainstream": {
  en: `Back`,
  no: `Tillbake`,
},
"Complete_aggressive": {
  en: `Complete`,
  no: `Fullfør`,
},
"Complete_casual": {
  en: `Complete`,
  no: `Fullfør`,
},
"Complete_mainstream": {
  en: `Complete`,
  no: `Fullfør`,
},
"Next_aggressive": {
  en: `Next`,
  no: `Neste`,
},
"Next_casual": {
  en: `Next`,
  no: `Neste`,
},
"Next_mainstream": {
  en: `Next`,
  no: `Neste`,
},
"No_aggressive": {
  en: `No`,
  no: `Nei`,
},
"No_casual": {
  en: `No`,
  no: `Nei`,
},
"No_mainstream": {
  en: `No`,
  no: `Nei`,
},
"Yes_aggressive": {
  en: `Yes`,
  no: `Ja`,
},
"Yes_casual": {
  en: `Yes`,
  no: `Ja`,
},
"Yes_mainstream": {
  en: `Yes`,
  no: `Ja`,
},
//LP02 CTA
"Message Me_aggressive": {
  en: `Message Me`,
  no: `Send melding til meg`,
},
"Message Me_casual": {
  en: `Message Me`,
  no: `Send melding til meg`,
},
"Message Me_mainstream": {
  en: `Message Me`,
  no: `Send melding til meg`,
},
"Subscribe_aggressive": {
  en: `Subscribe`,
  no: `Abonnere`,
},
"Subscribe_casual": {
  en: `Subscribe`,
  no: `Abonnere`,
},
"Subscribe_mainstream": {
  en: `Subscribe`,
  no: `Abonnere`,
},
//LP03 CTA
"I Agree_aggressive": {
  en: `I Agree`,
  no: `Jeg er enig`,
},
"I Agree_casual": {
  en: `I Agree`,
  no: `Jeg er enig`,
},
"I Agree_mainstream": {
  en: `I Agree`,
  no: `Jeg er enig`,
},
"Continue_aggressive": {
  en: `Continue`,
  no: `Fortsette`,
},
"Continue_casual": {
  en: `Continue`,
  no: `Fortsette`,
},
"Continue_mainstream": {
  en: `Continue`,
  no: `Fortsette`,
},
};
