import React, { useEffect, useState } from "react";
import { Box, useTheme, styled, Typography } from "@mui/material";
import { ButtonNext } from "./../common/ButtonNext";
import { useDispatch, useSelector } from "react-redux";
import { next } from "../../features/activeStepSlice";
import axios from "axios";
import { InputComp } from "../common/InputComp";
import { ErrorMessage } from "../common/ErrorMessage";
import { useDebounce } from "../../hooks/useDebounce";
import { addErrorMessage } from "../../features/errorMessageSlice";
import { addField } from "../../features/userDataSlice";
import { Translate } from "react-translated";

const UserNameCompBox = styled(Box)(({ theme }) => ({
  margin: "16px auto 0",
  width: "343px",
  maxWidth: "100%",

  [theme.breakpoints.up("sm")]: {
    width: "466px",
    margin: "24px auto 0",
  },
}));

const StepContent12 = () => {
  const userData = useSelector((state) => state.userData?.value);
  const [username, setUsername] = useState(userData.username || "");
  const apiUrl = axios.create({ baseURL: process.env.REACT_APP_API_URL });
  const dispatch = useDispatch();
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const regex = /^[_&?!#0-9a-zA-Z\u00c0-\u017e]{3,12}$/;
  const message = "usernameErrMessFE";
  const useDebounceValue = useDebounce(username, 500, regex, message);
  const theme = useTheme();

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length < 13) {
      setUsername(value);
    }
    dispatch(addErrorMessage(""));
  };

  useEffect(() => {
    if (useDebounceValue || !errorMessage) {
      dispatch(addField({ username: useDebounceValue }));
    } else {
      dispatch(addField({ username: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useDebounceValue]);

  const fetchData = async () => {
    try {
      const response = await apiUrl.post(
        `/registration/start`,
        {
          username: useDebounceValue,
          recaptcha_token: null,
        },
        {
          params: {
            site_key: "no01",
          },
        }
      );
      if (response?.data.Status === "ok") {
        sessionStorage.setItem("user_id", response?.data.Data);
        dispatch(next());
        dispatch(addErrorMessage(""));
      }
    } catch (error) {
      if (error?.response?.data.Status === "fail") {
        dispatch(addErrorMessage(error?.response?.data?.Error?.message));
        localStorage.removeItem("uid");
      }
    }
  };

  const handleNext = () => {
    if (useDebounceValue) {
      fetchData();
    }
  };

  return (
    <UserNameCompBox className="userBox">
      {!errorMessage ? (
        <Typography
          variant="Body4"
          sx={{
            color: theme.palette.grey,
            marginBottom: "16px",
            fontSize: { xs: "12px", sm: "14px" },
            lineHeight: { xs: "18px", sm: "24px" },
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
          component="p"
        >
          <Translate text="usernameText" />
        </Typography>
      ) : (
        <ErrorMessage errorMessage={errorMessage} />
      )}

      <InputComp
        name="username"
        value={username}
        handleChange={handleChange}
        placeholder="usernamePlaceholder"
        typeInput="text"
        isValid={!useDebounceValue || !!errorMessage}
        autoFocus={true}
      />

      <ButtonNext
        text="Continue"
        onClick={handleNext}
        style={{ cursor: useDebounceValue ? "pointer" : "no-drop" }}
        disabled={!useDebounceValue || !!errorMessage}
      />
    </UserNameCompBox>
  );
};

export default StepContent12;
