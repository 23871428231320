import React from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Translate } from "react-translated";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GeneralTermsOfServiceDialog } from "../../dialog/GeneralTermsOfServiceDialog";
import { PrivacyStatementDialog } from "../../dialog/PrivacyStatementDialog";

const DialogTitleComp = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontWeight: "700",
});

const DialogContentStyle = styled(DialogContent)(({ theme }) => ({
  userSelect: "none",
  fontWeight: "400",
  color: "rgb(99, 115, 129)",
  padding: "0",
  marginBottom: "16px",

  [theme.breakpoints.up("sm")]: {
    padding: "0 24px",
    marginBottom: "24px",

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#cacaca",
      border: "4px solid transparent",
      borderRadius: "8px",
      backgroundClip: "padding-box",
    },

    "&::-webkit-scrollbar": {
      width: "16px",
    },
  },
}));

export const ModalComp = ({ open, handleClose, type }) => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const titleText = {
    terms: "General Terms of Service",
    privacy: "Privacy Statement",
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={titleText[type]}
      fullWidth
      fullScreen={isSmScreen}
      maxWidth="xl"
      PaperProps={{
        sx: {
          borderRadius: {
            xs: "0px",
            sm: "16px",
          },
        },
      }}
    >
      <DialogTitleComp id="modal-modal-title">
        {type && <Translate text={titleText[type]} />}
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitleComp>

      <DialogContentStyle>
        {type === "terms" && <GeneralTermsOfServiceDialog />}
        {type === "privacy" && <PrivacyStatementDialog />}
      </DialogContentStyle>
    </Dialog>
  );
};
