import React from "react";
import {
  Checkbox,
  FormControlLabel,
  styled,
  useTheme,
  useMediaQuery,
  FormGroup,
  Typography,
  Box,
} from "@mui/material";
import { TextComp } from "./TextComp";

const FormGroupStyles = styled(FormGroup)(({ theme }) => ({
  margin: "16px auto 0",
  width: "343px",
  paddingLeft: "64px",
  boxSizing: "border-box",
  maxWidth: "100%",

  [theme.breakpoints.up("sm")]: {
    marginTop: "24px",
    width: "478px",
    paddingLeft: "104px",
  },
}));

const FormControlLabelStyle = styled(FormControlLabel)(({ theme }) => ({
  marginRight: "0",
  display: "flex",
  alignItems: "flex-start",
  marginBottom: "12px",
  marginLeft: 0,
  userSelect: "none",

  ":last-child": {
    marginBottom: 0,
  },

  [theme.breakpoints.up("sm")]: {
    marginBottom: "16px",
  },
}));

const CheckboxStyle = styled(Checkbox)(({ theme }) => ({
  marginRight: "0",
  display: "flex",
  alignItems: "flex-start",
  padding: "0",
  color: theme.palette.grey,

  "&:hover": {
    backgroundColor: "transparent",
  },

  "&.Mui-checked": {
    color: theme.palette.green,

    "& + span > p": {
      color: theme.palette.black,
    },
  },

  [theme.breakpoints.up("sm")]: {},
}));

export const MultipleCheckbox = ({
  arrayData,
  checkedItems,
  handleCheckboxChange,
  stepNumber,
  text,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <FormGroupStyles>
      {text && (
        <Box sx={{ display: "flex" }}>
          <Typography variant="Body2">*</Typography>
          <TextComp
            variant="Body2"
            text={text}
            sx={{
              marginBottom: { xs: "12px", sm: "16px" },
              fontSize: { xs: "16px", sm: "18px" },
            }}
            component="em"
          />
        </Box>
      )}
      {arrayData?.map((item) => (
        <FormControlLabelStyle
          key={item}
          control={
            <CheckboxStyle
              checked={checkedItems[item] || false}
              onChange={() => handleCheckboxChange(item)}
            />
          }
          label={
            <TextComp
              variant={isSmallScreen ? "Body4" : "Body1"}
              text={`step${stepNumber}_${item}`}
              sx={{ marginLeft: "10px", color: theme.palette.grey }}
              component="p"
            />
          }
        />
      ))}
    </FormGroupStyles>
  );
};
