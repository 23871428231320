import React from "react";
import { Typography, Box, useTheme } from "@mui/material";
import { DialogOrderList } from "./dialog_components/DialogOrderList";
import { DialogSubtitle } from "./dialog_components/DialogSubtitle";
import { DialogParagraph } from "./dialog_components/DialogParagraph";
import { DialogImagSVG } from "./dialog_components/DialogImagSVG";
import { DialogBoldText } from "./dialog_components/DialogBoldText";
import { dialogContent } from "../dataUI/dialogContent";
import {
  changesPricesData,
  complaintsData,
  contentData,
  contentuseData,
  creditRatesData,
  definitionsData,
  descriptionData,
  intellectualPropertyData,
  liabilityData,
  privacyInfoData,
  scopeData,
  timelinessData,
  userDisputesData,
} from "./../dataUI/dialogData";

export const GeneralTermsOfServiceDialog = () => {
  const theme = useTheme();
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;
  const company_name = require(`./text_svg/Company_name_${dynamicType}.svg`);
  const reg_no = require(`./text_svg/Reg_no_${dynamicType}.svg`);
  const company_address = require(`./text_svg/Company_address_${dynamicType}.svg`);
  const company_country = require(`./text_svg/Company_country_${dynamicType}.svg`);

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{ padding: { xs: "0 16px 24px", sm: "0" } }}
      onContextMenu={handleContextMenu}
    >
      <DialogParagraph text="general_terms_of_service_content_1" />
      <DialogParagraph text="general_terms_of_service_content_2" />
      <DialogBoldText
        text="general_terms_of_service_content_3"
        sx={{ mt: 2, fontSize: "16px" }}
      />
      <Box sx={{ ml: 2 }}>
        {descriptionData?.map((item) => (
          <DialogParagraph
            key={item}
            text={`general_terms_of_service_content_${item}`}
          />
        ))}
      </Box>
      {/* 1. Definitions */}
      <DialogSubtitle text="general_terms_of_service_content_16" />
      <Box sx={{ display: "flex", mt: 1, ml: 1 }}>
        <Box
          component="span"
          sx={{ mr: 3, fontSize: "14px", color: theme.palette.darkGrey }}
        >
          1.1
        </Box>
        <Typography variant="Body5" component="p">
          <DialogImagSVG src={company_name} sx={{ top: "3px" }} />:{" "}
          {dialogContent["general_terms_of_service_content_17"]}
          <DialogImagSVG src={reg_no} sx={{ marginLeft: "6px" }} />,{" "}
          {dialogContent["general_terms_of_service_content_18"]}{" "}
          <DialogImagSVG src={company_address} sx={{ top: "3px" }} />{" "}
          <DialogImagSVG src={company_country} sx={{ top: "3px" }} />.{" "}
          {dialogContent["general_terms_of_service_content_19"]}
        </Typography>
      </Box>
      <DialogOrderList arrayData={definitionsData} />
      {/*2. Scope and Characteristics of the Conditions  */}
      <DialogSubtitle text="general_terms_of_service_content_30" />
      <DialogOrderList arrayData={scopeData} />
      {/* 3. Content and Use of intimflorts.com */}
      <DialogSubtitle text="general_terms_of_service_content_35" />
      <DialogOrderList arrayData={contentuseData} />
      {/* 4. Liability */}
      <DialogSubtitle text="general_terms_of_service_content_51" />
      <DialogOrderList arrayData={liabilityData} />
      {/* 5. Content */}
      <DialogSubtitle text="general_terms_of_service_content_56" />
      <DialogOrderList arrayData={contentData} />
      {/*6. Privacy, Personal Information, Communications, and Website Usage */}
      <DialogSubtitle text="general_terms_of_service_content_65" />
      <DialogOrderList arrayData={privacyInfoData} />
      {/* 7. Credit Rates and Payments */}
      <DialogSubtitle text="general_terms_of_service_content_72" />
      <DialogOrderList arrayData={creditRatesData} />
      {/* 8. Timeliness of Refunds (Time of Reflection)*/}
      <DialogSubtitle text="general_terms_of_service_content_81" />
      <DialogOrderList arrayData={timelinessData} />
      {/* 9. Complaints and Resolutions */}
      <DialogSubtitle text="general_terms_of_service_content_83" />
      <DialogOrderList arrayData={complaintsData} />
      {/* 10. User Disputes */}
      <DialogSubtitle text="general_terms_of_service_content_87" />
      <DialogOrderList arrayData={userDisputesData} />
      {/* 11. Intellectual Property Rights*/}
      <DialogSubtitle text="general_terms_of_service_content_89" />
      <DialogOrderList arrayData={intellectualPropertyData} />
      {/* 12. Changes in Prices and General Conditions */}
      <DialogSubtitle text="general_terms_of_service_content_94" />
      <DialogOrderList arrayData={changesPricesData} />
      {/* 13. Governing Law */}
      <DialogSubtitle text="general_terms_of_service_content_97" />
      <DialogParagraph
        text="general_terms_of_service_content_98"
        sx={{ ml: 2 }}
      />
    </Box>
  );
};
