import React from "react";
import { ButtonNext } from "./../common/ButtonNext";
import { useDispatch, useSelector } from "react-redux";
import { next } from "../../features/activeStepSlice";
import { addField } from "../../features/userDataSlice";
import { ButtonComp } from "./../common/ButtonComp";

const StepContent9 = () => {
  const activeStep = useSelector((state) => state.activeStep.value);
  const userData = useSelector((state) => state.userData?.value);
  const dispatch = useDispatch();

  const handleChange = (selectedGender) => {
    dispatch(addField({ gender: selectedGender }));
  };

  const handleNext = () => {
    if (userData.gender) {
      dispatch(
        addField({
          looking_for: userData.gender === "Male" ? "Female" : "Male",
        })
      );
      dispatch(next());
    }
  };

  return (
    <>
      <ButtonComp
        sx={{
          borderStyles: {
            border: `1px solid ${
              userData?.gender === "Female" ? "#F76448" : "#B2B3B5"
            }`,
            width: { xs: "343px", sm: "466px" },
            margin: { xs: "24px auto 16px" },
            maxWidth: "100%",
          },
          textStyles: {
            color: userData?.gender === "Female" ? "#F76448" : "#B2B3B5",
          },
        }}
        onClick={() => handleChange("Female")}
        text={`step${activeStep}_female`}
      />
      <ButtonComp
        sx={{
          borderStyles: {
            border: `1px solid ${
              userData?.gender === "Male" ? "#F76448" : "#B2B3B5"
            }`,
            width: { xs: "343px", sm: "466px" },
            margin: { xs: "0 auto" },
            maxWidth: "100%",
          },
          textStyles: {
            color: userData?.gender === "Male" ? "#F76448" : "#B2B3B5",
          },
        }}
        onClick={() => handleChange("Male")}
        text={`step${activeStep}_male`}
      />
      <ButtonNext text="Continue" onClick={handleNext} />
    </>
  );
};

export default StepContent9;
