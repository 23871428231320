import React from "react";
import { Typography } from "@mui/material";
import { Translate } from "react-translated";

export const TextComp = ({
  variant = "",
  text = "",
  sx = {},
  component = "",
  onClick = null,
}) => {
  const dynamicType = process.env.REACT_APP_DYNAMIC_TYPE;

  return (
    <Typography
      variant={variant}
      sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word", ...sx }}
      component={component}
      onClick={onClick}
    >
      <Translate text={`${text}_${dynamicType}`} />
    </Typography>
  );
};
