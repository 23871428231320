export const mainContent = {
  //Main content translation
  "titleText_aggressive": {
    en: `Only for men: FREE MEMBERSHIP. Register now!`,
    no: `Bare for menn: gratis medlemskap. Registrer deg nå!`,
  },
  "titleText_casual": {
    en: `Only for men: FREE MEMBERSHIP. Register now!`,
    no: `Bare for menn: gratis medlemskap. Registrer deg nå!`,
  },
  "titleText_mainstream": {
    en: `Only for men: FREE MEMBERSHIP. Register now!`,
    no: `Bare for menn: gratis medlemskap. Registrer deg nå!`,
  },
  "mainTimetimerText_aggressive": {
    en: `Time remaining...`,
    no: `Tiden igjen ...`,
  },
  "mainTimetimerText_casual": {
    en: `Time remaining...`,
    no: `Tiden igjen ...`,
  },
  "mainTimetimerText_mainstream": {
    en: `Time remaining...`,
    no: `Tiden igjen ...`,
  },
  "step1_warning_aggressive": {
    en: `WARNING:`,
    no: `ADVARSEL:`,
  },
  "step1_warning_casual": {
    en: `WARNING:`,
    no: `ADVARSEL:`,
  },
  "step1_warning_mainstream": {
    en: `WARNING:`,
    no: `ADVARSEL:`,
  },
  "step1_warning_text_aggressive": {
    en: `You might find flirty pictures of someone you know on this page.`,
    no: `Du kan finne flørtende bilder av noen du kjenner på denne siden.`,
  },
  "step1_warning_text_casual": {
    en: `You might find flirty pictures of someone you know on this page.`,
    no: `Du kan finne flørtende bilder av noen du kjenner på denne siden.`,
  },
  "step1_warning_text_mainstream": {
    en: `You might find flirty pictures of someone you know on this page.`,
    no: `Du kan finne flørtende bilder av noen du kjenner på denne siden.`,
  },
  "step1_disclaimer_aggressive": {
    en: `PLEASE READ THE RULES BEFORE YOU REGISTER`,
    no: `Les reglene før du registrerer deg`,
  },
  "step1_disclaimer_casual": {
    en: `PLEASE READ THE RULES BEFORE YOU REGISTER`,
    no: `Les reglene før du registrerer deg`,
  },
  "step1_disclaimer_mainstream": {
    en: `PLEASE READ THE RULES BEFORE YOU REGISTER`,
    no: `Les reglene før du registrerer deg`,
  },
  "step1_disclaimer_text1_aggressive": { //Disclaimer 1
    en: `Respect the privacy of other members and do not revealtheir identity. You agree to respect the sexual desires and preferences of our members.`,
    no: `Respekter personvernet til andre medlemmer og ikke revealtheir identitet. Du samtykker i å respektere de seksuelle ønsker og preferanser til medlemmene våre.`,
  },
  "step1_disclaimer_text1_casual": {
    en: `Respect the privacy of other members and do not revealtheir identity. You agree to respect the sexual desires and preferences of our members.`,
    no: `Respekter personvernet til andre medlemmer og ikke revealtheir identitet. Du samtykker i å respektere de seksuelle ønsker og preferanser til medlemmene våre.`,
  },
  "step1_disclaimer_text1_mainstream": {
    en: `Respect the privacy of other members and do not revealtheir identity. You agree to respect the sexual desires and preferences of our members.`,
    no: `Respekter personvernet til andre medlemmer og ikke revealtheir identitet. Du samtykker i å respektere de seksuelle ønsker og preferanser til medlemmene våre.`,
  },
  "step1_disclaimer_text2_aggressive": {
    en: `Please be discreet.`,
    no: `Vær diskret.`,
  },
  "step1_disclaimer_text2_casual": {
    en: `Please be discreet.`,
    no: `Vær diskret.`,
  },
  "step1_disclaimer_text2_mainstream": {
    en: `Please be discreet.`,
    no: `Vær diskret.`,
  },
  "step2_question_aggressive": { //Disclaimer 2
    en: `Before we can show you a list and photos of members who are ready to have a chat right now, we need to ask a few quick questions.`,
    no: `Før vi kan vise deg en liste og bilder av medlemmer som er klare til å ta en prat akkurat nå, må vi stille noen raske spørsmål.`,
  },
  "step2_question_casual": {
    en: `Before we can show you a list and photos of members who are ready to have a chat right now, we need to ask a few quick questions.`,
    no: `Før vi kan vise deg en liste og bilder av medlemmer som er klare til å ta en prat akkurat nå, må vi stille noen raske spørsmål.`,
  },
  "step2_question_mainstream": {
    en: `Before we can show you a list and photos of members who are ready to have a chat right now, we need to ask a few quick questions.`,
    no: `Før vi kan vise deg en liste og bilder av medlemmer som er klare til å ta en prat akkurat nå, må vi stille noen raske spørsmål.`,
  },
  "step3_question_aggressive": { //Disclaimer 3
    en: `Many of these women are desperately single and looking for some fun. They could be one of your neighbours or someone you know. Do you agree to keep the identity of these women a secret?`,
    no: `Mange av disse kvinnene er desperat single og leter etter litt moro. De kan være en av naboene dine eller noen du kjenner. Er du enig i å holde identiteten til disse kvinnene hemmelig?`,
  },
  "step3_question_casual": {
    en: `Many of these women are desperately single and looking for some fun. They could be one of your neighbours or someone you know. Do you agree to keep the identity of these women a secret?`,
    no: `Mange av disse kvinnene er desperat single og leter etter litt moro. De kan være en av naboene dine eller noen du kjenner. Er du enig i å holde identiteten til disse kvinnene hemmelig?`,
  },
  "step3_question_mainstream": {
    en: `Many of these women are desperately single and looking for some fun. They could be one of your neighbours or someone you know. Do you agree to keep the identity of these women a secret?`,
    no: `Mange av disse kvinnene er desperat single og leter etter litt moro. De kan være en av naboene dine eller noen du kjenner. Er du enig i å holde identiteten til disse kvinnene hemmelig?`,
  },
  "step4_question_aggressive": { //Disclaimer 4
    en: `These women have asked us not to allow men who are seeking a 'relationship'. They only want to play. Are you comfortable with this?`,
    no: `Disse kvinnene har bedt oss om ikke å tillate menn som søker et 'forhold'. De vil bare spille. Er du komfortabel med dette?`,
  },
  "step4_question_casual": {
    en: `These women have asked us not to allow men who are seeking a 'relationship'. They only want to play. Are you comfortable with this?`,
    no: `Disse kvinnene har bedt oss om ikke å tillate menn som søker et 'forhold'. De vil bare spille. Er du komfortabel med dette?`,
  },
  "step4_question_mainstream": {
    en: `These women have asked us not to allow men who are seeking a 'relationship'. They only want to play. Are you comfortable with this?`,
    no: `Disse kvinnene har bedt oss om ikke å tillate menn som søker et 'forhold'. De vil bare spille. Er du komfortabel med dette?`,
  },  
  "step5_question_aggressive": { //Disclaimer 5
    en: `Are you at least 24 years old? These women have requested that we don't let anyone younger than 24 contact them, due to previous experiences.`,
    no: `Er du minst 24 år gammel? Disse kvinnene har bedt om at vi ikke lar noen yngre enn 24 kontakte dem på grunn av tidligere erfaringer.`,
  },
  "step5_question_casual": {
    en: `Are you at least 24 years old? These women have requested that we don't let anyone younger than 24 contact them, due to previous experiences.`,
    no: `Er du minst 24 år gammel? Disse kvinnene har bedt om at vi ikke lar noen yngre enn 24 kontakte dem på grunn av tidligere erfaringer.`,
  },
  "step5_question_mainstream": {
    en: `Are you at least 24 years old? These women have requested that we don't let anyone younger than 24 contact them, due to previous experiences.`,
    no: `Er du minst 24 år gammel? Disse kvinnene har bedt om at vi ikke lar noen yngre enn 24 kontakte dem på grunn av tidligere erfaringer.`,
  },
  //Body type
  "step6_question_aggressive": {
    en: `Which body type do you prefer?`,
    no: `Hvilken kroppstype foretrekker du?`,
  },
  "step6_question_casual": {
    en: `Which body type do you prefer?`,
    no: `Hvilken kroppstype foretrekker du?`,
  },
  "step6_question_mainstream": {
    en: `Which body type do you prefer?`,
    no: `Hvilken kroppstype foretrekker du?`,
  }, 
  "step6_skinny_aggressive": {
    en: `Skinny`,
    no: `Mager`,
  },
  "step6_skinny_casual": {
    en: `Skinny`,
    no: `Mager`,
  },
  "step6_skinny_mainstream": {
    en: `Skinny`,
    no: `Mager`,
  },
  "step6_big_aggressive": {
    en: `Big`,
    no: `Stor`,
  },
  "step6_big_casual": {
    en: `Big`,
    no: `Stor`,
  },
  "step6_big_mainstream": {
    en: `Big`,
    no: `Stor`,
  },
  "step6_normal_aggressive": {
    en: `Normal and/or Model`,
    no: `Normal og/eller modell`,
  },
  "step6_normal_casual": {
    en: `Normal and/or Model`,
    no: `Normal og/eller modell`,
  },
  "step6_normal_mainstream": {
    en: `Normal and/or Model`,
    no: `Normal og/eller modell`,
  },
  "step6_dontKnow_aggressive": {
    en: `Not sure`,
    no: `Ikke sikker`,
  },
  "step6_dontKnow_casual": {
    en: `Not sure`,
    no: `Ikke sikker`,
  },
  "step6_dontKnow_mainstream": {
    en: `Not sure`,
    no: `Ikke sikker`,
  },
  //Hair color
  "step7_question_aggressive": {
    en: `Which hair color do you prefer?`,
    no: `Hvilken hårfarge foretrekker du?`,
  },
  "step7_question_casual": {
    en: `Which hair color do you prefer?`,
    no: `Hvilken hårfarge foretrekker du?`,
  },
  "step7_question_mainstream": {
    en: `Which hair color do you prefer?`,
    no: `Hvilken hårfarge foretrekker du?`,
  },
  "step7_blonde_aggressive": {
    en: `Blonde`,
    no: `Blond`,
  },
  "step7_blonde_casual": {
    en: `Blonde`,
    no: `Blond`,
  },
  "step7_blonde_mainstream": {
    en: `Blonde`,
    no: `Blond`,
  },
  "step7_brunette_aggressive": {
    en: `Brunette`,
    no: `Brunette`,
  },
  "step7_brunette_casual": {
    en: `Brunette`,
    no: `Brunette`,
  },
  "step7_brunette_mainstream": {
    en: `Brunette`,
    no: `Brunette`,
  }, 
  "step7_redhead_aggressive": {
    en: `Redhead`,
    no: `Rødhåret`,
  },
  "step7_redhead_casual": {
    en: `Redhead`,
    no: `Rødhåret`,
  },
  "step7_redhead_mainstream": {
    en: `Redhead`,
    no: `Rødhåret`,
  },
  "step7_dontKnow_aggressive": {
    en: `Not sure`,
    no: `Ikke sikker`,
  },
  "step7_dontKnow_casual": {
    en: `Not sure`,
    no: `Ikke sikker`,
  },
  "step7_dontKnow_mainstream": {
    en: `Not sure`,
    no: `Ikke sikker`,
  },
  "step7_warning_text_aggressive": {
    en: `Choose up to 2`,
    no: `Velg opptil 2`,
  },
  "step7_warning_text_casual": {
    en: `Choose up to 2`,
    no: `Velg opptil 2`,
  },
  "step7_warning_text_mainstream": {
    en: `Choose up to 2`,
    no: `Velg opptil 2`,
  },
  "step8_question_aggressive": { //Disclaimer 6
    en: `You may now see our list and photos of women who are seeking crazy experiences. Again, please keep their identity a secret. Click on the 'Continue' button and start flirting.`,
    no: `Du kan nå se listen vår og bilder av kvinner som søker vanvittige opplevelser. Igjen, vær så snill å holde identiteten deres hemmelig. Klikk på 'Fortsett' -knappen og begynn å flørte.`,
  },
  "step8_question_casual": {
    en: `You may now see our list and photos of women who are seeking crazy experiences. Again, please keep their identity a secret. Click on the 'Continue' button and start flirting.`,
    no: `Du kan nå se listen vår og bilder av kvinner som søker vanvittige opplevelser. Igjen, vær så snill å holde identiteten deres hemmelig. Klikk på 'Fortsett' -knappen og begynn å flørte.`,
  },
  "step8_question_mainstream": {
    en: `You may now see our list and photos of women who are seeking crazy experiences. Again, please keep their identity a secret. Click on the 'Continue' button and start flirting.`,
    no: `Du kan nå se listen vår og bilder av kvinner som søker vanvittige opplevelser. Igjen, vær så snill å holde identiteten deres hemmelig. Klikk på 'Fortsett' -knappen og begynn å flørte.`,
  },
  //Gender
  "step9_question_aggressive": {
    en: `What is your gender?`,
    no: `Hva er ditt kjønn?`,
  },
  "step9_question_casual": {
    en: `What is your gender?`,
    no: `Hva er ditt kjønn?`,
  },
  "step9_question_mainstream": {
    en: `What is your gender?`,
    no: `Hva er ditt kjønn?`,
  },
  "step9_female_aggressive": {
    en: `Female`,
    no: `Hunn`,
  },
  "step9_female_casual": {
    en: `Female`,
    no: `Hunn`,
  },
  "step9_female_mainstream": {
    en: `Female`,
    no: `Hunn`,
  },
  "step9_male_aggressive": {
    en: `Male`,
    no: `Mann`,
  },
  "step9_male_casual": {
    en: `Male`,
    no: `Mann`,
  },
  "step9_male_mainstream": {
    en: `Male`,
    no: `Mann`,
  },
  //Age
  "step10_question_aggressive": {
    en: `When is your birthday?`,
    no: `Når har du bursdag?`,
  },
  "step10_question_casual": {
    en: `When is your birthday?`,
    no: `Når har du bursdag?`,
  },
  "step10_question_mainstream": {
    en: `When is your birthday?`,
    no: `Når har du bursdag?`,
  },
  //Geo
  "step11_question_aggressive": {
    en: `Where are you?`,
    no: `Hvor er du?`,
  },
  "step11_question_casual": {
    en: `Where are you?`,
    no: `Hvor er du?`,
  },
  "step11_question_mainstream": {
    en: `Where are you?`,
    no: `Hvor er du?`,
  },
  //Username
  "step12_question_aggressive": {
    en: `Choose a username`,
    no: `Velg et brukernavn`,
  },
  "step12_question_casual": {
    en: `Choose a username`,
    no: `Velg et brukernavn`,
  },
  "step12_question_mainstream": {
    en: `Choose a username`,
    no: `Velg et brukernavn`,
  },
  //Password
  "step13_question_aggressive": {
    en: `Set your password`,
    no: `Angi passordet ditt`,
  },
  "step13_question_casual": {
    en: `Set your password`,
    no: `Angi passordet ditt`,
  },
  "step13_question_mainstream": {
    en: `Set your password`,
    no: `Angi passordet ditt`,
  },
  //Email
  "step14_question_aggressive": {
    en: `What is your email?`,
    no: `Hva er epostadressen din?`,
  },
  "step14_question_casual": {
    en: `What is your email?`,
    no: `Hva er epostadressen din?`,
  },
  "step14_question_mainstream": {
    en: `What is your email?`,
    no: `Hva er epostadressen din?`,
  }, 
  // Placeholder and static text
  "locationPlaceholder": {
    en: `Type your city, for example London`,
    no: `Skriv din by, for eksempel Oslo`,
  },
  "usernamePlaceholder": {
    en: `Username`,
    no: `Brukernavn`,
  },
  "passwordPlaceholder": {
    en: `Password`,
    no: `Passord`,
  },
  "emailPlaceholder": {
    en: `Add your email address`,
    no: `Legg til e -postadressen din`,
  },
  "ageText": {
    en: `You must be at least 18 years old to use IntimFlorts.`,
    no: `Du må være minst 18 år for å bruke IntimFlørts.`,
  },
  "locationText": {
    en: `Search location by city, country or zip code`,
    no: `Søkeplassering etter by-, land- eller postnummer`,
  },
  "usernameText": {
    en: `Username must be 3-12 characters should exclude any personal details.`,
    no: `Brukernavnet må være 3-12 tegn og bør utelukke personlige detaljer.`,
  },
  "passwordText": {
    en: `The password must be more than 6 characters long and include at least one number`,
    no: `Passordet må være lengre enn 6 tegn og inkludere minst ett tall`,
  },
  "emailText": {
    en: `Your email will not be disclosed publicly and will not be shared with external entities.`,
    no: `E -postadressen din blir ikke avslørt offentlig og vil ikke bli delt med eksterne enheter.`,
  },
  // Birthday selecte options
  "step10_day": {
    en: `Day`,
    no: `Dag`,
  }, 
  "step10_month": {
    en: `Month`,
    no: `Måned`,
  },  
  "step10_year": {
    en: `Year`,
    no: `År`,
  },
  //ToS-labels
  "read and accept": {
    en: `I have read and accept the`,
    no: `Jeg har lest og aksepterer`,
  },
  "Terms of Service": {
    en: `Terms of Service`,
    no: `Tjenestevilkår`,
  },
  "and our": {
    en: `and our`,
    no: `og vår`,
  },
  "Privacy Statement": {
    en: `Privacy Statement`,
    no: `Personvernerklæring`,
  },
  //Months
  "January": {
    en: `January`,
    no: `Januar`,
  },
  "February": {
    en: `February`,
    no: `Februar`,
  },
  "March": {
    en: `March`,
    no: `Mars`,
  },
  "April": {
    en: `April`,
    no: `April`,
  },
  "May": {
    en: `May`,
    no: `Mai`,
  },
  "June": {
    en: `June`,
    no: `Juni`,
  },
  "July": {
    en: `July`,
    no: `Juli`,
  },
  "August": {
    en: `August`,
    no: `August`,
  },
  "September": {
    en: `September`,
    no: `September`,
  },
  "October": {
    en: `October`,
    no: `Oktober`,
  },
  "November": {
    en: `November`,
    no: `November`,
  },
  "December": {
    en: `December`,
    no: `Desember`,
  },
  //frontend error message translation content
  "locationErrMessFE": {
    en: `Location not found`,
    no: `Plassering ikke funnet`,
  },
  "usernameErrMessFE": {
    en: `The username must be 3-12 characters long and can only consist of letters, underscores, and standard special characters`,
    no: `Brukernavnet må være 3-12 tegn langt og kan bare bestå av bokstaver, understrekinger og standard spesialtegn.`,
  },
  "passwordErrMessFE": {
    en: `Your password doesn't meet our guidelines. Password must be 6-20 characters and contain at least one digit.`,
    no: `Passordet ditt oppfyller ikke våre retningslinjer. Passordet må være 6-20 tegn og inneholde minst ett tall.`,
  },
  "emailErrMessFE": {
    en: `The email address is not valid, please try again`,
    no: `E-postadressen er ikke gyldig, vennligst prøv igjen`,
  },
  //backend error message translation content
  "Field already exists.": {
    en: `An account containing identical details already exists in our system`,
    no: `En konto med identiske opplysninger finnes allerede i vårt system`,
  },
  "Google recaptcha error.": {
    en: `There has been an error with the Google reCAPTCHA verification process`,
    no: `Det har oppstått en feil med Google reCAPTCHA-verifiseringsprosessen`,
    },
  "Google recaptcha user suspicious behaviour.": {
    en: `Google reCAPTCHA has detected suspicious activity associated with your behavior`,
    no: `Google reCAPTCHA har oppdaget mistenkelig aktivitet knyttet til din oppførsel`,
  },
  "Provide google recaptcha token.": {
    en: `Please provide the token generated by the Google reCAPTCHA for verification`,
    no: `Vennligst oppgi tokenet generert av Google reCAPTCHA for verifikasjon`,
  },
  "Provide valid email.": {
    en: `Please enter a valid email address to proceed`,
    no: `Vennligst oppgi en gyldig e-postadresse for å fortsette`,
  },
  "User age must be above 18 years.": {
    en: `Users must be at least 18 years old to create an account`,
    no: `Brukere må være minst 18 år gamle for å opprette en konto`,
  }, 
  "Wrong user id provided.": {
    en: `The user ID you have provided is incorrect or does not exist`,
    no: `Bruker-ID-en du har oppgitt er feil eller eksisterer ikke`,
  },  
  //Duplicate from backend error response
  "Username requirements don't match.": {
    en: `The username must be 3-12 characters long and can only consist of letters, underscores, and standard special characters`,
    no: `Brukernavnet må være 3-12 tegn langt og kan bare bestå av bokstaver, understrekinger og standard spesialtegn.`,
  },  
  "Password requirements don't match.": {
    en: `Your password doesn't meet our guidelines. Password must be 6-20 characters and contain at least one digit.`,
    no: `Ditt passord overholder ikke våre krav. Det skal være på 6-20 tegn og må inkludere minst ett tall.`,
  },
};
