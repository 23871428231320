import React, { useState, useEffect } from "react";
import { Box, styled, useTheme, useMediaQuery } from "@mui/material";
import { TextComp } from "./common/TextComp";
import { useDispatch } from "react-redux";

const TimerBox = styled(Box)(({ theme }) => ({
  width: "110px",
  height: "44px",
  margin: "0 auto",
  borderRadius: "4px",
  padding: "8px 18px",
  boxSizing: "border-box",
  border: `2px solid ${theme.palette.grey}`,
  fontSize: "24px",
  fontWeight: "700",
  lineHeight: "28px",
  letterSpacing: "0em",
  display: "flex",
  color: `${theme.palette.blue}`,

  [theme.breakpoints.up("md")]: {
    fontSize: "48px",
    lineHeight: "60px",
    height: "76px",
    width: "190px",
  },
}));

export const TimerComp = () => {
  const [minutes, setMinutes] = useState(process.env.REACT_APP_TIMER_MINUTES);
  const [seconds, setSeconds] = useState(0);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const timer = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        clearInterval(timer);
        setMinutes(process.env.REACT_APP_TIMER_MINUTES);
      } else if (seconds === 0) {
        setMinutes((prevMinutes) => prevMinutes - 1);
        setSeconds(59);
      } else {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [minutes, seconds, dispatch]);

  return (
    <Box>
      <TextComp
        variant={isSmallScreen ? "Body3" : "Body1"}
        text="mainTimetimerText"
        sx={{
          textAlign: "center",
          mb: { xs: "6px", sm: "10px" },
          color: "#3265AA",
        }}
        component="p"
      />
      <TimerBox>
        <Box
          sx={{
            width: { xs: "30px", sm: "63px" },
            mr: 1 / 2,
            textAlign: "right",
          }}
        >
          {String(minutes).padStart(2, "0")}
        </Box>{" "}
        :
        <Box
          sx={{
            width: { xs: "32px", sm: "65px" },
            ml: 1 / 2,
            textAlign: "left",
          }}
        >
          {String(seconds).padStart(2, "0")}
        </Box>
      </TimerBox>
    </Box>
  );
};
