export const progressBarCenter = (activeStep) => {
  const calculateTransform = (screenWidth) => {
    switch (true) {
      case screenWidth < 600:
        return 320; //+ 375w
      case screenWidth >= 600 && screenWidth < 960:
        return 210; //+ 500w
      case screenWidth >= 960 && screenWidth < 1280:
        return 190; //+ 800w
      default:
        return 0;
    }
  };
  const windowWidth = calculateTransform(window.innerWidth);
  const transformleft =
    activeStep >= 4 && activeStep <= 10
      ? (windowWidth / 6) * (activeStep - 4)
      : activeStep > 10
      ? windowWidth
      : 0;

  return transformleft;
};
