import React from 'react'
import { ButtonComp } from './ButtonComp';
import { ButtonNext } from './ButtonNext';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { next } from '../../features/activeStepSlice';

export const ButtonsGroup = () => {
  const dispatch = useDispatch();

  const handleNext = () => {
    dispatch(next());
  };
  return (
    <Box
      sx={{
        margin: "0 auto",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <ButtonComp
        text="No"
        onClick={handleNext}
        sx={{
          borderStyles: {
            width: { xs: "156px", sm: "200px", md: "225px" },
            marginRight: { xs: "12px" },
          },
        }}
      />
      <ButtonNext
        text="Yes"
        onClick={handleNext}
        sx={{ width: { xs: "156px", sm: "200px", md: "225px" } }}
      />
    </Box>
  );
}
