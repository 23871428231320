import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1900,
    },
  },

  palette: {
    orange: "#F76448",
    darkOrange: "#B74A35",
    blue: "#3265AA",
    black: "#212B36",
    grey: "#B2B3B5",
    darkGrey: "#637381",
    lightGrey: "#E5E8EB",
    red: "#FF0000",
    white: "#FFF",
    green: "#14AE5C",
    lightGreen: "#2EC876",
  },

  typography: {
    Subtitle1: {
      fontSize: "24px",
      color: "#212B36",
      fontWeight: "600",
      lineHeight: "38px",
      textAlign: "center",
    },
    Subtitle2: {
      fontSize: "18px",
      color: "#212B36",
      fontWeight: "600",
      lineHeight: "26px",
      textAlign: "center",
    },
    Subtitle3: {
      fontSize: "20px",
      color: "#212B36",
      fontWeight: "500",
      lineHeight: "32px",
    },
    Subtitle4: {
      fontSize: "16px",
      color: "#212B36",
      fontWeight: "600",
      lineHeight: "24px",
    },
    Body1: {
      fontSize: "18px",
      color: "#212B36",
      fontWeight: "400",
      lineHeight: "26px",
      textAlign: "center",
    },
    Body2: {
      fontSize: "18px",
      color: "#B2B3B5",
      fontWeight: "500",
      lineHeight: "26px",
    },
    Body3: {
      fontSize: "14px",
      color: "#B2B3B5",
      fontWeight: "400",
      lineHeight: "24px",
    },
    Body4: {
      fontSize: "16px",
      color: "#212B36",
      fontWeight: "400",
      lineHeight: "24px",
    },
    Body5: {
      fontSize: "14px",
      color: "#637381",
      fontWeight: "400",
      lineHeight: "22px",
    },
  },
});

export default theme;
