import React from "react";
import { Box, Stack, styled, useTheme, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { TextComp } from "./common/TextComp";
import { ImageWithStatus } from "./common/ImageWithStatus";

const QuestionCompStack = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "flex-start",
}));

const QuestionTextBox = styled(Box)(({ theme }) => ({
  padding: "18px",
  borderRadius: "0px 12px 12px 12px",
  background: "rgba(255, 255, 255, 0.5)",
  boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
  textAlign: "left",
  minWidth: "240px",
  maxWidth: "656px",
  boxSizing: "border-box",

  [theme.breakpoints.up("sm")]: {
    textAlign: "center",
  },
}));

export const QuestionComp = () => {
  const activeStep = useSelector((state) => state.activeStep.value);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <QuestionCompStack>
      <ImageWithStatus />
      {activeStep === 1 ? (
        <QuestionTextBox>
          <Box>
            <TextComp
              text={`step${activeStep}_warning`}
              variant={isSmallScreen ? "Body4" : "Body1"}
              sx={{ color: theme.palette.red, fontWeight: "600", mr: 1 / 2 }}
            />{" "}
            <TextComp
              text={`step${activeStep}_warning_text`}
              variant={isSmallScreen ? "Body4" : "Body1"}
              sx={{ color: theme.palette.black }}
            />
          </Box>
          <TextComp
            text={`step${activeStep}_disclaimer`}
            component="p"
            variant={isSmallScreen ? "Body4" : "Body1"}
            sx={{
              my: { xs: "12px", sm: "20px" },
              textAlign: { xs: "left", sm: "center" },
              fontWeight: "600",
              color: theme.palette.black,
            }}
          />
          <TextComp
            text={`step${activeStep}_disclaimer_text1`}
            component="p"
            variant={isSmallScreen ? "Body4" : "Body1"}
            sx={{
              color: theme.palette.black,
              textAlign: { xs: "left", sm: "center" },
            }}
          />
          <TextComp
            text={`step${activeStep}_disclaimer_text2`}
            component="p"
            variant={isSmallScreen ? "Body4" : "Body1"}
            sx={{
              color: theme.palette.black,
              textAlign: { xs: "left", sm: "center" },
            }}
          />
        </QuestionTextBox>
      ) : (
        <QuestionTextBox>
          <TextComp
            component="p"
            variant={isSmallScreen ? "Body4" : "Body1"}
            text={`step${activeStep}_question`}
            sx={{ textAlign: "left" }}
          />
        </QuestionTextBox>
      )}
    </QuestionCompStack>
  );
};
