import React from "react";
import { Typography } from "@mui/material";
import { dialogContent } from "../../dataUI/dialogContent";

export const DialogBoldWithRegularText = ({ num }) => {
  return (
    <Typography variant="Body5" paragraph sx={{ mb: 1 / 2 }}>
      <Typography variant="Body5" component="b" sx={{ fontWeight: "bold" }}>
        {dialogContent[`privacy_statement_content_${num}b`]}
      </Typography>{" "}
      <Typography variant="Body5" component="span">
        {dialogContent[`privacy_statement_content_${num}`]}
      </Typography>
    </Typography>
  );
};
