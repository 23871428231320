// General Terms Of Service Dialog data array
export const descriptionData = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export const definitionsData = [
  { order: "1.2.", num: 20 },
  { order: "1.3.", num: 21 },
  { order: "1.4.", num: 22 },
  { order: "1.5.", num: 23 },
  { order: "1.6.", num: 24 },
  { order: "1.7.", num: 25 },
  { order: "1.8.", num: 26 },
  { order: "1.9.", num: 27 },
  { order: "1.10.", num: 28 },
  { order: "1.11.", num: 29 },
];

export const scopeData = [
  { order: "2.1.", num: 31 },
  { order: "2.2.", num: 32 },
  { order: "2.3.", num: 33 },
  { order: "2.4.", num: 34 },
];

export const contentuseData = [
  { order: "3.1.", num: 36 },
  { order: "3.2.", num: 37 },
  { order: "3.3.", num: 38 },
  { order: "3.4.", num: 39 },
  { order: "3.5.", num: 40 },
  { order: "3.6.", num: 41 },
  { order: "3.7.", num: 42 },
  { order: "3.8.", num: 43 },
  { order: "3.9.", num: 44 },
  { order: "3.10.", num: 45 },
  { order: "3.11.", num: 46 },
  { order: "3.12.", num: 47 },
  { order: "3.13.", num: 48 },
  { order: "3.14.", num: 49 },
  { order: "3.15.", num: 50 },
];

export const liabilityData = [
  { order: "4.1.", num: 52 },
  { order: "4.2.", num: 53 },
  { order: "4.3.", num: 54 },
  { order: "4.4.", num: 55 },
];

export const contentData = [
  { order: "5.1.", num: 57 },
  { order: "5.2.", num: 58 },
  { order: "5.3.", num: 59 },
  { order: "5.4.", num: 60 },
  { order: "5.5.", num: 61 },
  { order: "5.6.", num: 62 },
  { order: "5.7.", num: 63 },
  { order: "5.8.", num: 64 },
];

export const privacyInfoData = [
  { order: "6.1.", num: 66 },
  { order: "6.2.", num: 67 },
  { order: "6.3.", num: 68 },
  { order: "6.4.", num: 69 },
  { order: "6.5.", num: 70 },
  { order: "6.6.", num: 71 },
];

export const creditRatesData = [
  { order: "7.1.", num: 73 },
  { order: "7.2.", num: 74 },
  { order: "7.3.", num: 75 },
  { order: "7.4.", num: 76 },
  { order: "7.5.", num: 77 },
  { order: "7.6.", num: 78 },
  { order: "7.7.", num: 79 },
  { order: "7.8.", num: 80 },
];

export const timelinessData = [{ order: "8.1.", num: 82 }];

export const complaintsData = [
  { order: "9.1.", num: 84 },
  { order: "9.2.", num: 85 },
  { order: "9.3.", num: 86 },
];

export const userDisputesData = [{ order: "10.1.", num: 88 }];

export const intellectualPropertyData = [
  { order: "11.1.", num: 90 },
  { order: "11.2.", num: 91 },
  { order: "11.3.", num: 92 },
  { order: "11.4.", num: 93 },
];

export const changesPricesData = [
  { order: "12.1.", num: 95 },
  { order: "12.2.", num: 96 },
];

// Privacy Statement Dialog data array
export const yourAccountData = [
  "privacy_statement_content_18",
  "privacy_statement_content_19",
  "privacy_statement_content_20",
];

export const automaticallyGeneratedData = [
  "privacy_statement_content_33",
  "privacy_statement_content_34",
  "privacy_statement_content_35",
  "privacy_statement_content_36",
  "privacy_statement_content_37",
  "privacy_statement_content_38",
];

export const yourRightsData = [
  "privacy_statement_content_43",
  "privacy_statement_content_44",
  "privacy_statement_content_45",
  "privacy_statement_content_46",
];

export const categoriesPersonalData1 = [
  "privacy_statement_content_56",
  "privacy_statement_content_57",
  "privacy_statement_content_58",
  "privacy_statement_content_59",
  "privacy_statement_content_60",
  "privacy_statement_content_61",
  "privacy_statement_content_62",
  "privacy_statement_content_63",
];

export const categoriesPersonalData2 = [
  "privacy_statement_content_65",
  "privacy_statement_content_66",
  "privacy_statement_content_67",
];

export const categoriesPersonalData3 = [
  "privacy_statement_content_71",
  "privacy_statement_content_72",
  "privacy_statement_content_73",
  "privacy_statement_content_74",
  "privacy_statement_content_75",
  "privacy_statement_content_76",
  "privacy_statement_content_77",
  "privacy_statement_content_78",
];

export const categoriesPersonalData4 = [
  "privacy_statement_content_80",
  "privacy_statement_content_81",
  "privacy_statement_content_82",
  "privacy_statement_content_83",
  "privacy_statement_content_84",
  "privacy_statement_content_85",
];

export const categoriesPersonalData5 = [
  "privacy_statement_content_89",
  "privacy_statement_content_90",
  "privacy_statement_content_91",
  "privacy_statement_content_92",
  "privacy_statement_content_93",
  "privacy_statement_content_94",
  "privacy_statement_content_95",
];
export const categoriesPersonalData6 = [
  "privacy_statement_content_97",
  "privacy_statement_content_98",
  "privacy_statement_content_99",
];

export const categoriesPersonalData7 = [
  "privacy_statement_content_101",
  "privacy_statement_content_102",
  "privacy_statement_content_103",
];
