import React from "react";
import { Box, useTheme, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { Translate } from "react-translated";

export const ErrorMessage = ({ errorMessage }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        marginBottom: "16px",
      }}
    >
      <ErrorIcon
        sx={{
          marginRight: { xs: "8px", sm: "12px" },
          width: { xs: "16px", sm: "24px" },
          height: { xs: "16px", sm: "24px" },
          color: theme.palette.red,
          paddingTop: "1px",
        }}
      />
      <Typography
        variant="Body4"
        sx={{
          color: theme.palette.red,
          fontSize: { xs: "12px", sm: "14px" },
          lineHeight: { xs: "18px", sm: "24px" },
        }}
      >
        <Translate text={errorMessage} />
      </Typography>
    </Box>
  );
};
