import React from "react";
import { Button, styled, useTheme, useMediaQuery } from "@mui/material";
import { TextComp } from "./TextComp";

const ButtonNextStles = styled(Button)(({ theme }) => ({
  height: "44px",
  width: "343px",
  borderRadius: "16px",
  margin: "28px auto 0",
  background: "#F76448",
  textTransform: "none",
  maxWidth: "100%",
  boxSizing: "border-box",
  padding: "10px",

  "&:hover": {
    backgroundColor: "rgba(247, 100, 72, 0.9)",
  },

  [theme.breakpoints.up("sm")]: {
    width: "100%",
  },

  [theme.breakpoints.up("md")]: {
    height: "48px",
    width: "466px",
    margin: "48px auto 0",
  },
}));

export const ButtonNext = ({
  text,
  disabled = false,
  sx = {},
  onClick = null,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ButtonNextStles onClick={onClick} disabled={disabled} sx={sx}>
      <TextComp
        text={text}
        variant={isSmallScreen ? "Body4" : "Body2"}
        sx={{
          color: disabled ? theme.palette.darkOrange : theme.palette.white,
        }}
      />
    </ButtonNextStles>
  );
};
