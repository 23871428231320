import React from "react";
import { ButtonNext } from "./../common/ButtonNext";
import { useDispatch, useSelector } from "react-redux";
import { next } from "../../features/activeStepSlice";
import { Box, styled, useTheme, Typography } from "@mui/material";
import { FormControlComp } from "../common/FormControlComp";
import { addField } from "../../features/userDataSlice";
import {
  generateOptions,
  generateYearOptions,
  getMonthsName,
} from "../../helpers/DateUtils";
import { ErrorMessage } from "../common/ErrorMessage";
import { Translate } from "react-translated";

const AgeDataBoxWrapper = styled(Box)(({ theme }) => ({
  margin: "16px auto 0",
  width: "343px",
  maxWidth: "100%",

  [theme.breakpoints.up("sm")]: {
    width: "466px",
    margin: "24px auto 0",
  },
}));

const AgeDataBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

const StepContent10 = () => {
  const userData = useSelector((state) => state.userData?.value);
  const errorMessage = useSelector((state) => state.errorMessage?.value);
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(addField({ [name]: value }));
  };

  const handleNext = () => {
    if (userData.day && userData.month && userData.year) {
      dispatch(next());
    }
  };

  return (
    <AgeDataBoxWrapper>
      {!errorMessage ? (
        <Typography
          variant="Body4"
          sx={{
            color: theme.palette.grey,
            marginBottom: "16px",
            fontSize: { xs: "12px", sm: "14px" },
            lineHeight: { xs: "18px", sm: "24px" },
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
          component="p"
        >
          <Translate text="ageText" />
        </Typography>
      ) : (
        <ErrorMessage errorMessage={errorMessage} />
      )}

      <AgeDataBox>
        <FormControlComp
          value={userData.day || ""}
          handleChange={handleChange}
          label="day"
          name="day"
          getOption={generateOptions(31)}
          labelId="day-label"
          id="day-select"
          sx={{ width: { xs: "24%", sm: "28%" } }}
        />
        <FormControlComp
          value={userData.month || ""}
          handleChange={handleChange}
          label="month"
          name="month"
          getOption={getMonthsName()}
          labelId="month-label"
          id="month-select"
          sx={{ width: { xs: "38%", sm: "34%" } }}
        />
        <FormControlComp
          value={userData.year || ""}
          handleChange={handleChange}
          label="year"
          name="year"
          getOption={generateYearOptions()}
          labelId="year-label"
          id="year-select"
          sx={{ width: { xs: "30%", sm: "28%" }, marginBottom: 0 }}
        />
      </AgeDataBox>

      <ButtonNext
        text="Continue"
        onClick={handleNext}
        disabled={!userData.day || !userData.month || !userData.year}
      />
    </AgeDataBoxWrapper>
  );
};

export default StepContent10;
