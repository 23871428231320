import React, { useState } from "react";
import { ButtonNext } from "./../common/ButtonNext";
import { useDispatch, useSelector } from "react-redux";
import { next } from "../../features/activeStepSlice";
import { addField } from "../../features/userDataSlice";
import { MultipleCheckbox } from "../common/MultipleCheckbox";
import { Box } from "@mui/material";

const StepContent6 = () => {
  const userData = useSelector((state) => state.userData?.value);
  const step6Data = ["skinny", "big", "normal", "dontKnow"];
  const dispatch = useDispatch();
  const [checkedItems, setCheckedItems] = useState(
    userData.step6 || {
      skinny: false,
      big: false,
      normal: false,
      dontKnow: false,
    }
  );
  const oneChecked = Object.values(checkedItems).some((value) => value);

  const handleCheckboxChange = (name) => {
    setCheckedItems((prev) => {
      const updatedState = {
        ...prev,
        [name]: !prev[name],
      };
      dispatch(addField({ step6: updatedState }));
      return updatedState;
    });
  };

  const handleNext = () => {
    dispatch(next());
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <MultipleCheckbox
        arrayData={step6Data}
        checkedItems={checkedItems}
        handleCheckboxChange={handleCheckboxChange}
        stepNumber={6}
      />
      <ButtonNext text="Continue" onClick={handleNext} disabled={!oneChecked} />
    </Box>
  );
};

export default StepContent6;
