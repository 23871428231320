import React from "react";
import { TextField, styled, Box, useTheme } from "@mui/material";
import { Translator } from "react-translated";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const InputBoxWrapper = styled(Box)(({ theme }) => ({
  border: "1px solid #F76448",
  background: "#fff",
  width: "100%",
  borderRadius: "16px",
  height: "44px",
  padding: "0 12px",
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",

  [theme.breakpoints.up("md")]: {
    height: "48px",
  },
}));

const InputBox = styled(TextField)(() => ({
  width: "100%",
}));

export const InputComp = ({
  name = "",
  value,
  handleChange,
  placeholder,
  typeInput,
  handleValidation,
  isValid = false,
  extraComp = "",
  sx = {},
  autoFocus = false,
}) => {
  const theme = useTheme();

  return (
    <InputBoxWrapper variant="Body1" sx={{ ...sx }}>
      <Translator>
        {({ translate }) => (
          <InputBox
            id={`${name}-field-id`}
            name={name}
            autoComplete="off"
            placeholder={translate({
              text: `${placeholder}`,
            })}
            className="inputBox"
            value={value}
            onChange={handleChange}
            onBlur={handleValidation}
            type={typeInput}
            autoFocus={autoFocus}
          />
        )}
      </Translator>
      {extraComp}
      {!isValid && <CheckCircleIcon sx={{ color: theme.palette.green }} />}
    </InputBoxWrapper>
  );
};
